<template>
    <div class="main-searchpage">
        <div style="width:100%;position:relative;">
            <Header :curActive="-1"></Header>
            <img class="main-searchpage-bg" src="../../assets/imgs/bg2.png" alt="">
            <div class="main_centerbox">
                <div v-if="dataList.length > 0" class="top-nav_box">
                    共搜索到大约 
                    <span>{{total}}</span>
                    条记录，用时：
                    <i>{{useTime}}</i>
                    秒
                </div>
                <ul v-if="dataList.length > 0" class="searchpage_data_list">
                    <li v-for="(item,i) in dataList" :key="i">
                        <a :href="item.contentIsChain == '1' ? item.contentChain : '/detail?docId='+item.id" target="_blank" style="display:block;">
                            <h3 v-html="item.contentTitle ? item.contentTitle : '暂无数据'"></h3>
                            <p v-html="item.contentDescription ? item.contentDescription : '暂无数据'"></p>
                            <div>
                                <span>作者：{{item.contentAuthor ? item.contentAuthor : '暂无数据'}}</span>
                                <i>时间：{{item.contentDatetime ? item.contentDatetime.substring(0,10) : '暂无数据'}}</i>
                            </div>
                        </a>
                    </li>
                </ul>

                <div v-if="dataList.length > 0" class="page_box">
                    <el-pagination
                        background
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="pageNum"
                        :page-size="pageSize"
                        layout="prev, pager, next, jumper"
                        :total="total">
                    </el-pagination>
                </div>
                <el-empty v-if="dataList.length <= 0" description="暂无数据!"></el-empty>
            </div>
            <Footer></Footer>
        </div>
        <!-- 回到顶部 -->
        <el-backtop target=".main-searchpage" :bottom="100">
            <!-- <div
                style="{
                    height: 100%;
                    width: 100%;
                    background-color: #f2f5f6;
                    box-shadow: 0 0 6px rgba(0,0,0, .12);
                    text-align: center;
                    line-height: 40px;
                    color: #1989fa;
                }"
            >
                UP
            </div> -->
            <i class="el-icon-caret-top"></i>
        </el-backtop>
    </div>
</template>

<script>

import $ from 'jquery';
import api from "@/../static/api/api.js";

import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';

import defaultImg1 from '@/assets/imgs/default.png'
import defaultImg2 from '@/assets/imgs/default2.png'
import defaultImg3 from '@/assets/imgs/default3.png'

export default {
    name: 'searchpage',
    data() {
        return {
            imgBase: this.$store.state.imgBase,
            defaultImg1: defaultImg1,//轮播图默认图片
            defaultImg2: defaultImg2,//研究项目默认图
            defaultImg3: defaultImg3,//交流传播默认图
            dataList: [],
            pageNum: 1,//分页的三个值
            pageSize: 8,
            total: 0,
            useTime: 0//请求所用时间
        }
    },
    components:{
        Header,
        Footer
    },
    mounted() {
        document.title = '搜索 | 知识中心'
        this.fetchNavData();
    },
    methods: {
        //获取左侧栏目列表函数
        fetchNavData(curNum=1){
            let _this = this;

            if(!this.getQueryString('keyword')){
                return;
            }

            let keyWord = this.getQueryString('keyword');
            let pageNum = curNum;
            let pageSize = this.pageSize;
            let timestamp1 = new Date().getTime();
            let projectId = '1453963868773634049';
            api.searchDataList(keyWord, pageNum, pageSize,projectId).then(res => {
                if (res.data) {
                    //记录请求所用时间
                    let timestamp2 = new Date().getTime();
                    _this.useTime = (timestamp2 - timestamp1)/1000;
      
                    _this.pageNum = res.data.pageNum;
                    _this.pageSize =res.data.pageSize;
                    _this.total =res.data.total;
                    // _this.dataList = res.data.list; 
                    _this.dataList = [];
                    let tmpList = res.data.list; 
                    if(tmpList.length > 0){//给关键词加颜色
                        for(let i=0;i<tmpList.length;i++){
                            if(tmpList[i].contentDescription){
                                tmpList[i].contentDescription = tmpList[i].contentDescription.replace(new RegExp(keyWord,'g'), '<span style="color:rgb(255, 0, 0)">'+keyWord+'</span>')
                            }
                            if(tmpList[i].contentTitle){
                                tmpList[i].contentTitle = tmpList[i].contentTitle.replace(new RegExp(keyWord,'g'), '<span style="color:rgb(255, 0, 0)">'+keyWord+'</span>')
                            }
                            if(tmpList[i].contentTitle){
                                tmpList[i].contentTitle = tmpList[i].contentTitle.replace(new RegExp(keyWord,'g'), '<span style="color:rgb(255, 0, 0)">'+keyWord+'</span>')
                            }
                            _this.dataList.push(tmpList[i]);
                        }
                    }
                }
            });
        },
        //分页操作函数1
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
        },
        //分页操作函数2
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.fetchNavData(val);
        },
        //获取URL参数
        getQueryString(key){
            var reg = new RegExp("(^|&)"+key+"=([^&]*)(&|$)");
            var result = window.location.search.substr(1).match(reg);
            return result?decodeURIComponent(result[2]):null;
        }
    }
}
</script>

<style scoped>
.main-searchpage{
    width:100%;
    height: 100vh;
    /* position: relative; */
    overflow-x:hidden;
}
.main-searchpage-bg{
    position: absolute;
    bottom:273px;
    left:0;
    z-index: -1;
}
.main_centerbox{
    width: 1200px;
    min-height: 430px;
    margin: 28px auto 0;
    overflow: hidden;
}
.top-nav_box{
    width: 100%;
    height: 35px;
    /* background: #F9F9F9; */
    font-size: 14px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #333333;
    line-height: 35px;
    text-align: right;
    color:#333;
}
.top-nav_box i{
    font-style: normal;
    color: #2FA82C;
    display: inline-block;
}
.top-nav_box span{
    color: #2FA82C;
}
.searchpage_data_list{
    width: 100%;
}
.searchpage_data_list li{
    width:100%;
    height:182px;
    border-bottom: 1px dashed #E3E3E3;
}
.searchpage_data_list li:last-child {
    border-bottom: none;
}
.searchpage_data_list li h3{
    width: 100%;
    height: 18px;
    font-size: 18px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #1D86C7;
    line-height: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-top: 25px;
    margin-bottom: 19px;
    text-align: left;
}
.searchpage_data_list li p{
    width: 100%;
    height: 56px;
    font-size: 15px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #4D4D4D;
    line-height: 30px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: left;
    overflow: hidden;
}
.searchpage_data_list li div{
    width:100%;
    height:16px;
    margin-top: 20px;
    overflow: hidden;
    font-size: 15px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #808080;
    text-align: left;
    line-height: 16px;
}
.searchpage_data_list li div span{
    line-height: 16px;
    display: inline-block;
}
.searchpage_data_list li div i{
    line-height: 16px;
    display: inline-block;
    font-style: normal;
    margin-left: 40px;
}


/* 分页 */
.page_box{
    width:100%;
    height:63px;
    margin-top:30px;
    margin-bottom:100px;
}





</style>
